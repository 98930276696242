import React from 'react'
import renderBlok from '@renderBlok'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { H3, MarkdownHandler } from '@system'
import { Box } from '@material-ui/core'
import fluidWidth from '@helpers/fluid-width'
import classNames from 'classnames'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'
import { monochrome } from '@helpers/palette'

const useStyles = makeStyles((theme) => ({
  headline: {
    textAlign: 'center',
    display: 'flex',
    marginTop: 0,
    justifyContent: 'center',
    paddingTop: (props) => (props.isSmallBannerVariant ? '0px' : '5rem'),
    marginBottom: (props) => props.isSmallBannerVariant && '0px',
    fontSize: (props) => props.isSmallBannerVariant && '16px',
    fontWeight: (props) => props.isSmallBannerVariant && 500,
    marginRight: (props) => props.isSmallBannerVariant && '18px',
    color: (props) =>
      (!!props.headlineColor &&
        !!props.headlineColor.color &&
        monochrome(props.headlineColor.color)) ||
      (props.isColorInvert
        ? theme.palette.common.white
        : theme.palette.secondary.main),
  },
  logosContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: 0,
    padding: (props) =>
      props.isSmallBannerVariant
        ? '0px'
        : props.ishomepageLogoBanner
        ? '2rem 2rem 2rem'
        : '2rem 2rem 4rem',
    flex: (props) => props.isSmallBannerVariant && 1,
  },
  logoParent: (props) => ({
    padding: '1rem',
    opacity: props.isGhostBannerVariant ? 0.5 : 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: fluidWidth(props.logos.length),
    [theme.breakpoints.down('xs')]: {
      width: '50%',
      padding: (props) => props.isPodcastModule && '0.6rem',
    },
    '& .gatsby-image-wrapper': {
      minWidth: '120px',
      width: '100%',
      height: 'auto',
      maxWidth: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
      [theme.breakpoints.between('xs', 'sm')]: {
        minWidth: '136px',
        width: '100%',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        minWidth: '80px',
        width: '100%',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        minWidth: '108px',
        width: '100%',
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: '153.6px',
        width: '100%',
      },
    },
    '& img': {
      maxHeight: (props) => props.isPodcastModule && '40px',
      objectFit: (props) => props.isPodcastModule && 'contain !important',
      top: (props) => props.isPodcastModule && '50% !important',
      transform: (props) => props.isPodcastModule && 'translateY(-50%)',
    },
  }),
  ctaContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 0 3rem',
  },
  cta: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& button': {
      padding: '8px 12px',
    },
    '& button:hover': {
      boxShadow:
        '0px 2px 4px -1px rgba(225,225,225,0.2), 0px 4px 5px 0px rgba(225,225,225,0.14), 0px 1px 10px 0px rgba(225,225,225,0.12) !important;',
    },
  },
  ctaWithRule: {
    '&:before': {
      content: '""',
      height: '2px',
      flex: 1,
      backgroundColor: theme.palette.background.paper,
      marginRight: '1rem',
    },
    '&:after': {
      content: '""',
      height: '2px',
      flex: 1,
      backgroundColor: theme.palette.background.paper,
      marginLeft: '1rem',
    },
  },
  background: ({ backgroundColor }) => ({
    backgroundColor:
      (backgroundColor &&
        backgroundColor.color &&
        monochrome(backgroundColor.color)) ||
      theme.palette.secondary.main,
  }),
  outerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    color: theme.palette.text.primary,
    textAlign: 'center',
    '& p': {
      fontSize: '24px',
      fontWeight: theme.typography.fontWeightLight,
    },
    '& h4, h5': {
      margin: 0,
      padding: '1.5rem 0 1rem',
    },
  },
}))

const CustomerLogosModule = (props) => {
  const {
    isColorInvert,
    headline,
    logos,
    cta,
    ctaWithRule,
    isFullWidth,
    variant,
    description,
    backgroundColor,
    shouldRenderH1,
  } = props.blok

  const isPodcastModule = props.isPodcastModule

  const isSmallBannerVariant =
    variant === 'smallBanner' || variant === 'ghostBanner'
  const isGhostBannerVariant = variant === 'ghostBanner'
  const ishomepageLogoBanner = variant === 'homepageLogoBanner'
  const classes = useStyles({
    ...props.blok,
    isSmallBannerVariant: isSmallBannerVariant,
    isGhostBannerVariant: isGhostBannerVariant,
    ishomepageLogoBanner: ishomepageLogoBanner,
    isPodcastModule,
  })

  const FullWidthBox = ({ children }) =>
    !!props.blok && isColorInvert ? (
      <Box className={classes.background}>{children}</Box>
    ) : (
      <Box>{children}</Box>
    )

  return (
    <SbEditable content={props.blok}>
      <FullWidthBox>
        <PageContainer
          backgroundColor={
            isColorInvert
              ? 'clear'
              : backgroundColor?.color
              ? backgroundColor.color
              : 'default'
          }
          parentWidth={isFullWidth}
        >
          <ContentContainer>
            <Box
              className={classNames({
                [classes.outerContainer]: !!isSmallBannerVariant,
              })}
            >
              {!!headline && (
                <H3
                  component={shouldRenderH1 ? 'h1' : 'h3'}
                  className={classes.headline}
                >
                  {headline}
                </H3>
              )}
              {!!description && (
                <Box className={classes.description}>
                  <MarkdownHandler>{description}</MarkdownHandler>
                </Box>
              )}
              <Box className={classes.logosContainer}>
                {!!logos &&
                  logos.map((blok) => (
                    <Box className={classes.logoParent} key={blok.image}>
                      <div>{renderBlok(blok)}</div>
                    </Box>
                  ))}
              </Box>
              {!!cta.length && (
                <Box className={classes.ctaContainer}>
                  <Box
                    className={classNames(classes.cta, {
                      [classes.ctaWithRule]: ctaWithRule,
                    })}
                  >
                    {renderBloks(cta)}
                  </Box>
                </Box>
              )}
            </Box>
          </ContentContainer>
        </PageContainer>
      </FullWidthBox>
    </SbEditable>
  )
}

export default CustomerLogosModule
